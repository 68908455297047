import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './dashboard.component';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from 'src/app/shared/shared.module';
import { BatchPatchServicesComponent } from './batch-services/batch-patch-services/batch-patch-services.component';
import { BatchGuardChild } from 'src/app/core/guard/batch.guard';
import { SharedPrimeNgModule } from 'src/app/shared/shared-prime-ng.module';
import { ReactiveFormsModule } from '@angular/forms';
import { BatchManageServices } from './batch-services/batch-manage-services/batch-manage-services.component';
import { DEFAULT_BASE_URL } from 'src/app/core/constants/batch.urls.constant';

const routes: Routes = [
    {
        path: '',
        redirectTo: DEFAULT_BASE_URL,
        pathMatch: 'full',
    },
    {
        path: 'batch',
        component: DashboardComponent,
        canActivateChild: [BatchGuardChild],
        children: [
            {
                path: 'services',
                component: BatchManageServices,
            },
            {
                path: 'services/add',
                component: BatchPatchServicesComponent,
            },
            {
                path: 'services/edit/:id',
                component: BatchPatchServicesComponent,
            },
        ],
    },
];

@NgModule({
    declarations: [
        DashboardComponent,
        BatchPatchServicesComponent,
        BatchManageServices
    ],
    imports: [
        CommonModule,
        RouterModule,
        SharedPrimeNgModule,
        ReactiveFormsModule,
        RouterModule.forChild(routes),
        SharedModule,
    ],
    providers: [],
})
export class DashboardModule {}
