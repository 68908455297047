<ng-container *ngIf="isAllowed">
    <div class="container-fluid h-100">
        <div class="row h-100">
            <div class="col-md-2 side-content">
                <app-sidebar [sideBarMenu]="sideBarMenu" [userInfo]="userLoginInfo$ | async"></app-sidebar>
            </div>
            <div class="col-md main-content">
                <div class="p-57">
                    <app-header [backUrl]="backUrl" [headerOptions]="headerOptions"></app-header>

                </div>
                <div class="page-wrapper" [ngClass]="classWrapper">
                    <router-outlet></router-outlet>
                </div>
            </div>
        </div>
    </div>
    <p-toast position="bottom-center" key="bc"></p-toast>
    <p-toast position="top-center" key="tc"></p-toast>
    <p-toast position="bottom-right" key="br"></p-toast>
    <p-toast position="bottom-left" key="bl"></p-toast>
</ng-container>

<ng-container *ngIf="!isAllowed">
    <div class="container-fluid h-100">
        <div class="row h-100">
            <div class="col-md-2 side-content">
                <app-sidebar [sideBarMenu]="sideBarMenu" [userInfo]="userLoginInfo$ | async"
                    [infoOnly]="true"></app-sidebar>
            </div>
            <div class="col-md main-content">
                <div class="p-57">
                    <app-header [logoutOnly]="true"></app-header>
                </div>
                <div class="page-wrapper" [ngClass]="classWrapper">
                </div>
            </div>
        </div>
    </div>
</ng-container>

<app-side-panel [isVisible]="isSidePanelVisible" [showCloseIcon]="false">
    <div class="action-wrapper">
        <div class="title-header">
            <h1><span class="pi pi-exclamation-circle"></span> Error </h1>
        </div>
    </div>
    <ng-container>
        <div class="action-wrapper">
            <div class="container">
                <div class="title">You Do Not Have Access</div>
                <p>For assistance, please contact MotorWeb support.</p>
                <div>
                    <button pButton (click)="isSidePanelVisible = false"
                        class="mt-11 w-100 text-center p-element p-ripple p-button-outlined btn-style p-button p-component">
                        Close
                    </button>
                </div>
            </div>
        </div>
    </ng-container>

</app-side-panel>