import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { MessageService } from 'primeng/api';
import { lastValueFrom, Observable, of, switchMap } from 'rxjs';
import { Batch, BatchServiceInfo } from 'src/app/core/model/batch';
import { BatchService } from 'src/app/core/services/batch.service';
import {
    TableActionEvent,
    TableColumns,
} from 'src/app/shared/components/dynamic-table/dynamic-table.component';
import { BatchToolsService } from 'src/app/core/services/batch.tools.service';
import { BatchServiceStore } from 'src/app/core/state/batches/batch.services.store';
import { SessionQuery } from 'src/app/core/state/session/session.query';
import { UserAuth } from 'src/app/core/model/user.auth';
import { BatchServicesQuery } from 'src/app/core/state/batches/batch.services.query';
import { BatchSvc } from 'src/app/core/model/batch.svc';

@UntilDestroy()
@Component({
    selector: 'app-batch-overview',
    styleUrls: ['./batch-manage-services.component.scss'],
    templateUrl: './batch-manage-services.component.html',
})
export class BatchManageServices implements OnInit {

    userInfo: UserAuth = this.sessionQuery.getValue();
    title: string = 'All Services';
    currentSidePanel: string | 'cancel-panel' = '';
    currentSelectedData: Batch | any = null;
    isSidePanelVisible: boolean = false;
    batchServiceQuery$: Observable<BatchSvc[]> = this.batchServiceQuery.selectAll();
    batchServiceIsLoading$: Observable<boolean> = this.batchServiceQuery.selectLoading();
    loader = this.loadingBar.useRef();
    isAdmin: boolean = false;
    isAllUser: boolean = false;
    columnTable: TableColumns[] = [
        {
            field: 'country',
            header: 'Country',
            type: 'text',
            styleCss: 'width: 150px;',
        },
        {
            field: 'name',
            header: 'Service Name',
            type: 'text',
        },
        {
            field: 'batchServiceId',
            header: 'Edit',
            type: 'action-icon',
            actionIcon: 'icon icon-edit',
            styleCss: 'width: 50px; text-align: center;',
            action: 'edit'
        },
        {
            field: 'admin',
            header: 'Admin',
            type: 'boolean-select',
            styleCss: 'width: 100px; text-align: center;',
            action: 'admin'
        },
        {
            field: 'batchServiceId',
            header: 'Delete',
            type: 'action-icon',
            actionIcon: 'icon icon-remove',
            styleCss: 'width: 50px; text-align: center;',
            action: 'remove'
        },
    ];

    constructor(
        private router: Router,
        private batchService: BatchService,
        private batchServiceStore: BatchServiceStore,
        private batchServiceQuery: BatchServicesQuery,
        private batchServiceTool: BatchToolsService,

        private loadingBar: LoadingBarService,
        private messageService: MessageService,
        private sessionQuery: SessionQuery
    ) { }

    async ngOnInit() {
        this.batchServiceStore.setLoading(true);
        this.batchServiceQuery.selectBatchServiceIsLoaded$.pipe(
            untilDestroyed(this),
            switchMap((isLoaded) => {
                if (!isLoaded) {
                    this.batchServiceStore.setLoading(true);
                    return this.batchServiceTool.getBatchServices();
                }
                return of(undefined);
            })
        ).subscribe(() => this.batchServiceStore.setLoading(false));

    }

    actionEvents(action: TableActionEvent) {
        switch (action.action) {
            case 'remove':
                this.isSidePanelVisible = true;
                this.currentSidePanel = 'cancel-panel';
                this.currentSelectedData = action.rowData;
                break;
            case 'admin':
                this.isSidePanelVisible = true;
                this.currentSidePanel = 'admin-panel';
                this.currentSelectedData = action.rowData;
                this.isAdmin = !action.rowData.admin;
                break;
            case 'edit':
                this.router.navigate(['batch/services/edit/', action.rowData.batchServiceId])
                break;
            case 'enable':
                this.isSidePanelVisible = true;
                this.currentSidePanel = 'enable-panel';
                this.currentSelectedData = action.rowData;
                this.isAllUser = !action.rowData.enabled;
                break;
            case 'error':
                this.currentSelectedData = action.rowData;
                this.goToBatchErrorPage();
                break;
            default:
                break;
        }
    }

    goToAddBatch() {
        this.router.navigate(['batch/services/add']);
    }

    onHideEvent(value: any) {
        this.isSidePanelVisible = value;
    }

    async removeService() {
        this.batchServiceStore.setLoading(true);
        await lastValueFrom(
            this.batchServiceTool
                .deleteBatchService(this.currentSelectedData.batchServiceId)
                .pipe(untilDestroyed(this))
        );
        this.isSidePanelVisible = false;
        this.batchServiceStore.updateIsLoaded(false);
        
    }

    async patchService(isAdmin: boolean) {
        let msg = '';
        let enableDisable = 'enabled';
        this.batchServiceStore.setLoading(true);
        let currentSelectedData: BatchServiceInfo = { ...{}, ...this.currentSelectedData};
        if(isAdmin) {
            currentSelectedData.admin = !currentSelectedData.admin;
            enableDisable = currentSelectedData.admin ? 'enabled' : 'disabled';
            msg = `${this.currentSelectedData.service} service ${enableDisable} for Admin users.`;
        } else {
            currentSelectedData.enabled = !currentSelectedData.enabled;
            enableDisable = currentSelectedData.enabled ? 'enabled' : 'disabled';
            msg = `${this.currentSelectedData.service} service ${enableDisable} for all users.`;
        }
        const res = await lastValueFrom(
            this.batchServiceTool
                .updateBatchService(currentSelectedData)
                .pipe(untilDestroyed(this))
        );
        this.batchServiceStore.setLoading(false);
        this.isSidePanelVisible = false;
        this.batchServiceStore.updateIsLoaded(false);
        if(res && ('error' in res)) {
            this.messageService.add({
                key: 'br',
                severity: 'primary',
                summary: 'Success',
                detail: msg,
                closable: false,
            });
        }
    }
    

    async downloadBatch() {
        this.loader.start();
        this.messageService.add({
            key: 'br',
            severity: 'info',
            summary: 'Downloading ',
            detail: 'Downloading batch id ' + this.currentSelectedData.batchId,
            closable: false,
        });
        await lastValueFrom(
            this.batchService
                .downloadBatchReport(this.currentSelectedData.batchId)
                .pipe(untilDestroyed(this))
        );
        this.loader.complete();
    }

    async goToBatchErrorPage() {
        this.loader.start();
        const res = await lastValueFrom(
            this.batchService
                .getBatchError(
                    this.currentSelectedData.batchId,
                    this.currentSelectedData
                )
                .pipe(untilDestroyed(this))
        );
        this.loader.complete();
        if (res) {
            this.router.navigate([
                'batch/error/',
                this.currentSelectedData.batchId,
            ]);
        }
    }
}
