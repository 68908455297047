import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { UserAuth } from 'src/app/core/model/user.auth';

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent {
    constructor(private router: Router) {}

    @Input()
    userInfo: UserAuth | any;

    @Input()
    sideBarMenu: SideBarMenu[] = [];

    @Input()
    infoOnly: boolean = false;

    onClickNavigate(url?: any) {
        this.router.navigate([url]);
    }
}

export interface SideBarMenu {
    isActive: boolean;
    label: string;
    url: string;
    iconClass: string;
}
