import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-dynamic-table',
    templateUrl: './dynamic-table.component.html',
    styleUrls: ['./dynamic-table.component.scss'],
})
export class DynamicTableComponent {

    searchStringQuery: any = '';
    timeout: any;

    filteredData: any[] | any;
    private _tableData: TableData | undefined;

    @Input()
    set tableData(tableData: TableData | undefined) {
        if (tableData) {
            this._tableData = tableData;
            this.filteredData = tableData.data;
        }
    }

    get tableData(): TableData | any {
        return this._tableData;
    }

    @Input()
    isLoading: boolean | null | any = false;

    @Input()
    scrollHeight: string = 'flex';

    @Output()
    actionEvent: EventEmitter<TableActionEvent> =
        new EventEmitter<TableActionEvent>();

    @Input()
    allowManualSearch: boolean = false;


    onActionEvent(rowData: any, action: string) {
        this.actionEvent.emit({ rowData, action });
    }

    searchFilter() {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
            if (this.searchStringQuery == '') {
                this.filteredData = this.tableData?.data;
            } else {
                const { data } = this.tableData;
                const { cols } = this.tableData;
                this.filteredData = data.filter((item: any) => {
                    const values = Object.entries(item);
                    let allowedData = values.filter((data) => {
                        const [key, value] = data;
                        const findIndexValue = cols.find((dataCol: any) => dataCol.field == key);
                        if (findIndexValue) {
                            return value;
                        }
                    });
                    return allowedData.some((value: any) =>
                        value.toString().toLowerCase().includes(this.searchStringQuery.toLowerCase())
                    );
                });
            }
        }, 100);
    }


}

export interface TableColumns {
    field: string;
    header: string;
    type?:
    | string
    | 'id'
    | 'action'
    | 'text'
    | 'date'
    | 'combine'
    | 'progress-bar'
    | 'errors';
    actionIcon?: string;
    action?: string;
    combineFields?: any[];
    styleCss?: string;
    disabledFromField?: DisabledFromField;
}

export interface TableData {
    data: any;
    cols: TableColumns[];
}

export interface TableActionEvent {
    rowData?: any;
    action: any;
}

export interface DisabledFromField {
    field?: any | string;
    values?: any[];
}
