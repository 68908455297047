import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
} from '@angular/common/http';
import { SessionQuery } from '../state/session/session.query';
import { Observable, retry, switchMap, throwError, timer } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { SessionStore } from '../state/session/session.store';

export const maxRetries = 2;
export const delayMs = 2000;
@Injectable()
export class AuthInterceptorService implements HttpInterceptor {
    constructor(
        private sessionQuery: SessionQuery,
        private sessionStore: SessionStore,
        private authService: AuthService
    ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        const token = this.sessionQuery.getValue().accessToken;
        const exp = this.sessionQuery.getValue().userExtractedToken?.exp;
        const tokenExpire = this.authService.isTokenExpired(exp ? exp : null);
        const contentType = req.headers.get('Content-Type');
        const bearer = req.headers.get('Authorization');
        if (token && !tokenExpire) {
            const request = req.clone({
                setHeaders: {
                    'Content-Type': contentType
                        ? contentType
                        : 'application/json; charset=utf-8',
                    Accept: contentType ? contentType : 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            });
            return next.handle(request).pipe(retry({ count: maxRetries, delay: (err: any) => this.createNotifier$(err) }));

        } else if (bearer && !token) {
            const request = req.clone({
                setHeaders: {
                    'Content-Type': 'application/json; charset=utf-8',
                    Accept: contentType ? contentType : 'application/json',
                    Authorization: `${bearer}`,
                },
            });
            return next.handle(request);

        } else if (token) {
            return this.authService.renewToken().pipe(
                switchMap((newToken) => {
                    this.sessionStore.updateToken(newToken || token);
                    const request = req.clone({
                        headers: req.headers.set(
                            'Authorization',
                            'Bearer ' + (newToken || token)
                        ),
                    });
                    return next.handle(request);
                })
            );
        }
        return next.handle(req);
    }

    private createNotifier$(err: Error): Observable<unknown> {
        const isServerError = err.message.includes('401');
        return isServerError ? timer(delayMs) : throwError(() => err);
    }
}
