<div class="flex-info-wrapper row">
    <ng-container [ngSwitch]="displayType">
        <ng-container *ngSwitchCase="'user'">
            <ng-container *ngFor="let h of headerUserInfo">
                <div class="flex-info col-md">
                    <div class="flex-title">
                        {{ h.title }}
                    </div>
                    <div class="flex-label">
                        {{ h.value }}
                    </div>
                </div>
            </ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'batch-info'">
            <ng-container *ngFor="let h of headerBatchInfo">
                <ng-container [ngSwitch]="h.type">
                    <ng-container *ngSwitchCase="'status'">
                        <div class="flex-info col-md">
                            <div class="status-wrapper">
                                <span class="{{ h.value | statusIcon }}"></span>
                                <p>{{ h.value }}</p>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="'date'">
                        <div class="flex-info col-md">
                            <div class="flex-title">
                                {{ h.title }}
                            </div>
                            <div class="flex-label">
                                {{
                                    (h.value | dateOrEmpty)
                                        ? (h.value | date : 'dd/MM/yyyy')
                                        : 'Invalid Date'
                                }}
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngSwitchDefault>
                        <div class="flex-info col-md">
                            <div class="flex-title">
                                {{ h.title }}
                            </div>
                            <div class="flex-label">
                                {{ h.value }}
                            </div>
                        </div>
                    </ng-container>
                </ng-container>
            </ng-container>
        </ng-container>
    </ng-container>
</div>
