<div class="sidebar-wrapper">
    <div class="header">
        <app-logo></app-logo>
    </div>
    <div class="header-info" *ngIf="userInfo">
        <h3>{{ userInfo.displayName }}</h3>
        <span>{{
            userInfo.userExtractedToken && userInfo.userExtractedToken.user_id
                ? (userInfo.userExtractedToken.user_id | trimText)
                : ''
        }}</span>
    </div>
    <div class="menu">
        <ng-container *ngFor="let m of sideBarMenu">
            <div
                class="list"
                [class.active]="infoOnly ? false : m.isActive"
                [class.infoOnly]="infoOnly"
                (click)="infoOnly ? $event.preventDefault() : onClickNavigate(m.url)"
            >
                <span [ngClass]="m.iconClass"></span>
                <span class="label">{{ m.label }} </span>
            </div>
        </ng-container>
    </div>
</div>
