import { Pipe, PipeTransform } from '@angular/core';
import {
    BATCH_STATUS,
    BATCH_STATUS_LABEL,
    BATCH_STATUS_LABEL_TOOLTIP,
} from 'src/app/core/constants/batch.constant';

@Pipe({
    name: 'statusIcon',
})
export class StatusIconPipe implements PipeTransform {
    transform(value: any, labelType?: 'label' | 'tooltip' | string): any {
        let icon = '';
        let label = '';
        let tooltip = '';
        switch (value) {
            case BATCH_STATUS.PREPROCESSING:
                icon = 'icon icon-preprocessing';
                label = BATCH_STATUS_LABEL.PREPROCESSING;
                tooltip = BATCH_STATUS_LABEL_TOOLTIP.PREPROCESSING;
                break;
            case BATCH_STATUS.SCHEDULED:
                icon = 'icon icon-scheduled';
                label = BATCH_STATUS_LABEL.SCHEDULED;
                tooltip = BATCH_STATUS_LABEL_TOOLTIP.SCHEDULED;
                break;
            case BATCH_STATUS.PROCESSING:
                icon = 'icon icon-processing';
                label = BATCH_STATUS_LABEL.PROCESSING;
                tooltip = BATCH_STATUS_LABEL_TOOLTIP.PROCESSING;
                break;
            case BATCH_STATUS.COMPLETED:
                icon = 'icon icon-completed';
                label = BATCH_STATUS_LABEL.COMPLETED;
                tooltip = BATCH_STATUS_LABEL_TOOLTIP.COMPLETED;
                break;
            case BATCH_STATUS.FAILED:
                icon = 'icon icon-failed';
                label = BATCH_STATUS_LABEL.FAILED;
                tooltip = BATCH_STATUS_LABEL_TOOLTIP.FAILED;
                break;
            case BATCH_STATUS.CANCELLED:
                icon = 'icon icon-cancelled';
                label = BATCH_STATUS_LABEL.CANCELLED;
                tooltip = BATCH_STATUS_LABEL_TOOLTIP.CANCELLED;
                break;
            case BATCH_STATUS.EXPIRED:
                icon = 'icon icon-failed';
                label = BATCH_STATUS_LABEL.EXPIRED;
                tooltip = BATCH_STATUS_LABEL_TOOLTIP.EXPIRED;
                break;
            default:
                break;
        }
        return labelType ? (labelType == 'label' ? label : tooltip) : icon;
    }
}
