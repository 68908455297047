import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class FileHelper {
    constructor() {}

    downloadFile(url: string | any): void {
        const downloadTab: any = window.open(url, '_blank');
        downloadTab.focus();
        const pollTimer = setInterval(() => {
            if (downloadTab.closed) {
                clearInterval(pollTimer);
            }
        }, 500);
    }
}
