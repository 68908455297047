export const BATCH_STATUS = {
    FAILED: 'FAILED',
    PREPROCESSING: 'PRE_PROCESSING',
    SCHEDULED: 'SCHEDULED',
    PROCESSING: 'PROCESSING',
    COMPLETED: 'COMPLETED',
    CANCELLED: 'CANCELLED',
    EXPIRED: 'EXPIRED',
};

export const BATCH_STATUS_LABEL = {
    FAILED: 'FAILED',
    PREPROCESSING: 'PRE-PROCESSING',
    SCHEDULED: 'SCHEDULED',
    PROCESSING: 'PROCESSING',
    COMPLETED: 'COMPLETED',
    CANCELLED: 'CANCELLED',
    EXPIRED: 'EXPIRED',
};

export const BATCH_STATUS_LABEL_TOOLTIP = {
    FAILED: 'This vehicle batch encountered an error and was unable to start, please check the file format and try again.',
    PREPROCESSING: 'The system is preparing your vehicle batch for processing.',
    SCHEDULED:
        'This vehicle batch has been scheduled to run at [Time], please wait for it to start.',
    PROCESSING: 'The system is currently executing your vehicle batch.',
    COMPLETED:
        'This vehicle batch has finished, and the results are ready for viewing.',
    CANCELLED:
        'This vehicle batch has been cancelled and will not continue running.',
    EXPIRED: 'EXPIRED',
};
