import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { lastValueFrom, Observable, of, switchMap, zip } from 'rxjs';
import { Batch } from 'src/app/core/model/batch';
import { BatchToolsService } from 'src/app/core/services/batch.tools.service';
import { BatchErrorQuery } from 'src/app/core/state/batches/batch.errors.query';
import { BatchQuery } from 'src/app/core/state/batches/batch.query';
import { BatchServicesQuery } from 'src/app/core/state/batches/batch.services.query';
import { BatchServiceStore } from 'src/app/core/state/batches/batch.services.store';
import { BatchTypesQuery } from 'src/app/core/state/batches/batch.types.query';
import { BatchTypesStore } from 'src/app/core/state/batches/batch.types.store';
import { SessionQuery } from 'src/app/core/state/session/session.query';
import {
    BackUrl,
    HeaderOptions,
} from 'src/app/shared/components/header/header.component';
import { SideBarMenu } from 'src/app/shared/components/sidebar/sidebar.component';

@UntilDestroy()
@Component({
    selector: 'app-dashboard',
    styleUrls: ['./dashboard.component.scss'],
    templateUrl: './dashboard.component.html',
})
export class DashboardComponent implements OnInit {
    userLoginInfo$ = this.sessionQuery.select();
    batchInfo$: Observable<Batch> | undefined | any;
    backUrl: BackUrl | undefined = undefined;
    isEnableHeaderInfo: boolean = true;
    isSidePanelVisible: boolean = false;
    isAllowed: boolean = true;
    classWrapper = '';
    sideBarMenu: SideBarMenu[] = [
        {
            label: 'Bulk Data Services',
            url: '/batch/services',
            iconClass: 'icon icon-menu',
            isActive: true,
        },
        {
            label: 'Add Bulk Data Services',
            url: '/batch/services/add',
            iconClass: 'icon icon-plus',
            isActive: false,
        },
    ];
    displayType = 'user';

    headerOptions: HeaderOptions = {
        title: 'Bulk Data Services',
        icon: 'icon icon-menu',
    };

    constructor(
        private router: Router,
        private sessionQuery: SessionQuery,
        private batchErrorQuery: BatchErrorQuery,
        private batchServiceStore: BatchServiceStore,
        private batchToolsService: BatchToolsService,
        private batchTypesQuery: BatchTypesQuery,
        private batchTypesStore: BatchTypesStore
    ) { }

    ngOnInit(): void {
        const userInfo = this.sessionQuery.getValue();
        if (userInfo) {
            const { userExtractedToken } = userInfo as any;
            this.isAllowed = this.userIsAdmin(userExtractedToken);
        }

        if (!this.isAllowed) { this.isSidePanelVisible = true; return; }

        this.router.events.pipe(untilDestroyed(this)).subscribe((event) => {
            const events: any = event;
            const hasRouterURL = events['routerEvent'] && events['routerEvent'].url;
            if (event instanceof NavigationEnd || hasRouterURL) {
                if (!this.router.url) { return; }
                this.backUrl = undefined;
                this.displayType = 'user';
                this.classWrapper = '';
                this.isEnableHeaderInfo = true;
                this.sideBarMenu.map((data: SideBarMenu) => {
                    this.isEnableHeaderInfo = true;
                    data.isActive = false;
                    if (hasRouterURL) { data.isActive = events['routerEvent'].url == data.url; }
                    if (hasRouterURL && events['routerEvent'].url.includes('edit') && data.url === '/batch/services') {
                        data.isActive = true;
                        this.headerOptions.title = 'Edit Service';
                        this.headerOptions.icon = 'icon icon-edit';
                    }
                    if (data.isActive) {
                        this.headerOptions.icon = data.iconClass;
                        this.headerOptions.title = data.label;
                    }
                    return data;
                }
                );
            }
        });

        this.batchInfo$ = this.batchErrorQuery.selectBatchData$;

        zip(
            this.userLoginInfo$,
            this.batchTypesQuery.selectBatchTypeIsLoaded$
        )
            .pipe(
                untilDestroyed(this),
                switchMap(async (data) => {
                    const [user, isLoadedType] = data;
                    if (!isLoadedType && user) {
                        this.batchTypesStore.setLoading(true);
                        await lastValueFrom(
                            this.batchToolsService.getBatchTypes('')
                        );
                    }
                    return of(undefined);
                })
            )
            .subscribe(() => this.batchServiceStore.setLoading(false));

    }

    userIsAdmin(userExtractedToken: any): boolean {
        const { scopes } = userExtractedToken;
        const [ isAdmin ] = scopes.filter((data: any) => data == 'adm');
        return isAdmin ? true : false;
    }
}
