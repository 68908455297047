import { Component, Input } from '@angular/core';
import { Batch } from 'src/app/core/model/batch';
import { UserAuth } from 'src/app/core/model/user.auth';
import { LabelHelper } from '../../helper/label.helper';
import { TrimTextPipe } from '../../pipes/trim.text.pipe';

@Component({
    selector: 'app-header-info',
    templateUrl: './header-info.component.html',
    styleUrls: ['./header-info.component.scss'],
})
export class HeaderInfoComponent {
    headerUserInfo: HeaderInfo[] = [];
    otherInfo: HeaderInfo[] = [];
    headerBatchInfo: HeaderInfo[] = [];

    @Input()
    displayType: any | 'user' | 'other' | 'batch-info' = 'user';

    constructor(
        private labelHelper: LabelHelper,
        private trimText: TrimTextPipe
    ) { }

    private _userInfo!: UserAuth;
    @Input()
    set userInfo(userAuth: UserAuth | any) {
        this.headerUserInfo = [];
        if (userAuth) {
            const customerName: HeaderInfo = {
                title: 'Customer Name',
                value: userAuth.displayName,
            };
            const customerId: HeaderInfo = {
                title: 'Customer ID',
                value: this.trimText.transform(
                    userAuth.userExtractedToken &&
                        userAuth.userExtractedToken.user_id
                        ? userAuth.userExtractedToken.user_id
                        : ''
                ),
            };
            const customerCountry: HeaderInfo = {
                title: 'Country',
                value: this.labelHelper.getLabelCountry(
                    userAuth.userExtractedToken &&
                        userAuth.userExtractedToken.ctry
                        ? userAuth.userExtractedToken.ctry
                        : ''
                ),
            };
            this.headerUserInfo.push(customerName);
            this.headerUserInfo.push(customerId);
            this.headerUserInfo.push(customerCountry);
        }
    }

    get userInfo(): UserAuth {
        return this._userInfo;
    }

    private _batchInfo!: Batch;
    @Input()
    set batchInfo(batch: Batch | any) {
        this.headerBatchInfo = [];
        if (batch) {
            const batchStatus: HeaderInfo = {
                title: '',
                value: batch.status,
                type: 'status',
            };
            const batchId: HeaderInfo = {
                title: 'ID',
                value: this.trimText.transform(
                    batch && batch.batchId ? batch.batchId : ''
                ),
            };
            const batchServices: HeaderInfo = {
                title: 'Bulk Data Services',
                value: batch.batchServiceName,
            };

            const requestedDateTime: HeaderInfo = {
                title: 'Requested',
                value: batch.requestedDatetime,
                type: 'date',
            };
            const reference: HeaderInfo = {
                title: 'Reference',
                value: batch.userBatchRef,
            };
            const rows: HeaderInfo = {
                title: 'Rows',
                value: batch.rowsInBatch,
            };
            const error: HeaderInfo = {
                title: 'Errors',
                value: batch.validationErrors,
            };

            this.headerBatchInfo.push(batchStatus);
            this.headerBatchInfo.push(batchId);
            this.headerBatchInfo.push(batchServices);
            this.headerBatchInfo.push(requestedDateTime);
            this.headerBatchInfo.push(reference);
            this.headerBatchInfo.push(rows);
            this.headerBatchInfo.push(error);

            this._batchInfo = batch;
        }
    }

    get batch(): Batch {
        return this._batchInfo;
    }

    @Input()
    otherData!: HeaderInfo[];
}

export interface HeaderInfo {
    title: string;
    value: any;
    type?: 'status' | any;
}
