import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-side-panel',
    templateUrl: './side-panel.component.html',
    styleUrls: ['./side-panel.component.scss'],
})
export class SidePanelComponent {
    private _isVisible: boolean = false;

    @Input()
    set isVisible(isVisible: boolean) {
        this._isVisible = isVisible;
    }

    get isVisible() {
        return this._isVisible;
    }

    @Input()
    showCloseIcon: boolean = true;

    @Output()
    onHideEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

    onHide() {
        this.onHideEvent.emit(false);
    }
}
