
<form (ngSubmit)="onSubmit()" [formGroup]="batchServiceForm">
    <div class="row">
        <div class="col-md-12"> <div class="title">{{title}}</div></div>
        <div class="col-md">
            <div class="input-wrapper">
                <div class="p-fluid grid">
                    <span class="input-text-wrapper">
                        <label for="country">Country</label>
                        <p-dropdown placeholder="Select" formControlName="country"
                            [options]="country"></p-dropdown>
                    </span>
                </div>
                <div [hidden]="
                        batchServiceForm.controls['country'].valid ||
                        !batchServiceForm.controls['country'].dirty
                    " class="error-message"></div>
            </div>
            <div class="input-wrapper">
                <div class="p-fluid grid">
                    <span class="input-text-wrapper">
                        <label for="name">Bulk Data Service Name</label>
                        <input type="text" id="name" pInputText
                            formControlName="name" placeholder="" />
                    </span>
                </div>
                <div [hidden]="
                        batchServiceForm.controls['name'].valid ||
                        !batchServiceForm.controls['name'].dirty
                    " class="error-message">
                    This field is required
                </div>
            </div>


            <div class="input-wrapper">
                <div class="p-fluid grid">
                    <span class="input-text-wrapper">
                        <label for="failedTransStockCode">Finance Stock Code (Failed Trans)</label>
                        <input type="text" id="failedTransStockCode" pInputText formControlName="failedTransStockCode"
                            placeholder="" />
                    </span>
                </div>
                <div [hidden]="
                        batchServiceForm.controls['failedTransStockCode'].valid ||
                        !batchServiceForm.controls['failedTransStockCode'].dirty
                    " class="error-message">
                    This field is required
                </div>
            </div>
            <div class="input-wrapper">
                <div class="p-fluid grid">
                    <span class="input-text-wrapper">
                        <label for="successTransStockCode">Finance Stock code (Successful Trans)</label>
                        <input type="text" id="successTransStockCode" pInputText formControlName="successTransStockCode"
                            placeholder="" />
                    </span>
                </div>
                <div [hidden]="
                        batchServiceForm.controls['successTransStockCode'].valid ||
                        !batchServiceForm.controls['successTransStockCode'].dirty
                    " class="error-message">
                    This field is required
                </div>
            </div>
        </div>
        <div class="col-md">
            <div class="input-wrapper">
                <div class="p-fluid grid">
                    <span class="input-text-wrapper">
                        <label for="country">Bulk Data Service Type (API) </label>
                        <p-dropdown placeholder="Select" formControlName="serviceMapping"
                            [disabled]="!batchServiceForm.controls['country'].valid"
                            [options]="!batchServiceForm.controls['country'].valid ? [] : filteredByCountry(batchTypeData$ | async, batchServiceForm.controls['country'].value)" [optionLabel]="'description'" [optionValue]="'batchTypeCode'">
                        </p-dropdown>
                    </span>
                </div>
                <div [hidden]="
                        batchServiceForm.controls['country'].valid ||
                        !batchServiceForm.controls['country'].dirty
                    " class="error-message"></div>
            </div>

            <div class="input-wrapper">
                <div class="p-fluid grid">
                    <span class="input-text-wrapper p-textarea">
                        <label for="description">Bulk Data Service Description </label>
                        <textarea placeholder="" pInputTextarea formControlName="description"></textarea>
                    </span>
                </div>
                <div [hidden]="
                        batchServiceForm.controls['description'].valid ||
                        !batchServiceForm.controls['description'].dirty
                    " class="error-message"></div>
            </div>
            
            <div>
                <br />
                <button pButton type="submit" class="mt-11 p-element w-100 p-ripple p-button p-component"
                    [label]="(!isNew ? 'Save service' : 'Create a new service')" [disabled]="!(batchServiceForm.valid)"></button>
                <br /><br />
                <button pButton type="button" (click)="navigateOverview()"
                    class="mt-11 p-element w-100 p-ripple p-button-outlined p-button p-component"
                    label="Cancel "></button>
            </div>
        </div>
    </div>
</form>