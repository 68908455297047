import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import {
    AngularFirestore,
    AngularFirestoreDocument,
} from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import { UserAuth } from '../model/user.auth';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
    createInitialState,
    SessionStore,
} from '../state/session/session.store';
import { FirebaseError } from 'firebase/app';
import jwt_decode from 'jwt-decode';
import { Observable, catchError, from, map, of, switchMap } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
@UntilDestroy()
@Injectable({
    providedIn: 'root',
})
export class AuthService {
    userData: any;

    constructor(
        public afs: AngularFirestore,
        public afAuth: AngularFireAuth,
        public router: Router,
        private sessionStore: SessionStore,
        private http: HttpClient
    ) {
        this.afAuth.authState.pipe(untilDestroyed(this)).subscribe((user) => {
            if (user) {
                this.userData = user;
                const { accessToken } = this.userData.multiFactor.user;
                this.sessionStore.update({
                    ...this.userData.multiFactor.user,
                    ...{ accessToken: accessToken },
                    ...{ userExtractedToken: jwt_decode(accessToken) },
                });
                this.afAuth.updateCurrentUser(user);
            } else {
                this.sessionStore.update(createInitialState());
            }
        });
    }

    /**
     *
     * @param email input user email for firebase login
     * @param password  input user password for firebase login
     * @returns user and error values
     *
     */
    async signIn(email: string, password: string) {
        let errorMessage = '';
        try {
            const result = await this.afAuth.signInWithEmailAndPassword(
                email,
                password
            );
            this.setUserAuthData(result.user);
            this.sessionStore.setLoading(false);
            return { user: result.user, error: null };
        } catch (error: any) {
            if (error instanceof FirebaseError) {
                console.error(error.code);
            }
            switch (error.code) {
            case 'auth/invalid-email':
            case 'auth/wrong-password':
            case 'auth/user-not-found': {
                errorMessage = 'Wrong email address or password.';
                break;
            }
            default: {
                errorMessage = 'Unexpected Error';
                break;
            }
            }
            this.sessionStore.setLoading(false);
            return {
                user: null,
                error: errorMessage,
            };
        }
    }

    /**
     *
     * @param user | SET USER REF SESSION IN ANGULAR FIRE
     * @returns user reference for angular firedocument
     */
    setUserAuthData(user: any) {
        const userRef: AngularFirestoreDocument<any> = this.afs.doc(
            `users/${user.uid}`
        );
        const userData: UserAuth = {
            uid: user.uid,
            email: user.email,
            displayName: user.displayName,
            photoURL: user.photoURL,
            emailVerified: user.emailVerified,
            phoneNumber: user.phoneNumber,
            providerId: user.providerId,
        };
        return (
            userRef.set(userData),
            {
                merge: true,
            }
        );
    }

    async signOut() {
        return this.afAuth.signOut().then(() => {
            this.sessionStore.update(createInitialState());
            this.sessionStore.setLoading(false);
            if ('' + environment.unified == 'true') {
                window.location.href = `${environment.appUrl.authUrl}logout?logout=true&redirectUrl=${window.location.origin}`;
            } else {
                this.router.navigate(['auth/login']).then(() => {
                    location.reload();
                });
            }
        });
    }

    isTokenExpired(exp: number | null) {
        if (exp) {
            return new Date().getTime() > new Date(exp * 1000).getTime();
        }
        return true;
    }

    public renewToken(): Observable<unknown> {
        return from(this.afAuth.currentUser).pipe(
            switchMap((user) => {
                return user
                    ? from(user.getIdToken(true)).pipe(
                        map((token) => {
                            return token;
                        })
                    )
                    : of(null);
            })
        );
    }

    signInWithToken(auth: any) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json');
        headers = headers.set('Authorization',  `Bearer ${auth}`);
       
        let httpOptions = { headers: headers };
        
        return this.http.get<any>(`${environment.tokenUrl}`, httpOptions).pipe(
            switchMap(data=> {
                const { token } = data;
                return this.afAuth.signInWithCustomToken(token).then(user => {
                    return Promise.resolve(user);
                });
            }),
            catchError(()=> {
                return Promise.resolve(null);
            })
        );
    }
}
