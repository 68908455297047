import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { lastValueFrom, Observable } from 'rxjs';
import { DEFAULT_BASE_URL } from 'src/app/core/constants/batch.urls.constant';
import { BatchServiceInfo, BatchTypes } from 'src/app/core/model/batch';
import { BatchSvc, BatchSvcForm } from 'src/app/core/model/batch.svc';
import { BatchToolsService } from 'src/app/core/services/batch.tools.service';
import { BatchServicesQuery } from 'src/app/core/state/batches/batch.services.query';
import { BatchTypesQuery } from 'src/app/core/state/batches/batch.types.query';
import { BatchServiceStore } from 'src/app/core/state/batches/batch.services.store';

@Component({
    selector: 'app-batch-patch-services',
    templateUrl: './batch-patch-services.component.html',
    styleUrls: ['./batch-patch-services.component.scss'],
})
export class BatchPatchServicesComponent implements OnInit{
    @ViewChild('pUpload') pUpload: any;

    title: any = 'Create a new service';

    batchServiceData$: Observable<BatchSvc[] | any> =
        this.batchServicesQuery.selectAll();
    batchTypeData$: Observable<BatchTypes[] | any> =
        this.batchTypesQuery.selectAll();
    batchServiceId: any;
    isNew: boolean = true;
    batchServiceForm: FormGroup = new FormGroup({
        country: new FormControl<'AU' | 'NZ' | ''>('', [Validators.required]),
        name: new FormControl<string>('', [Validators.required]),
        description: new FormControl<string>('', [Validators.required]),
        successTransStockCode: new FormControl<string>('', [Validators.required]),
        failedTransStockCode: new FormControl<string>('', [Validators.required]),
        admin: new FormControl<boolean>(false),
        enabled: new FormControl<boolean>(true),
        serviceMapping: new FormControl<string>('', [Validators.required])
    });
    isBatchCreateLoading: boolean = false;
    currentData!: BatchServiceInfo;
    country: any [] = [ 'AU', 'NZ' ];

    constructor(
        private batchToolService: BatchToolsService,
        private batchServiceStore: BatchServiceStore,
        private batchServicesQuery: BatchServicesQuery,
        private batchTypesQuery: BatchTypesQuery,
        private router: Router,
        private route: ActivatedRoute,
        private messageService: MessageService
    ) { }


    async ngOnInit() {
        const batchServiceId = this.route.snapshot.paramMap.get('id');
        if(batchServiceId) {
            const result: any = await lastValueFrom(this.batchToolService.getBatchService(batchServiceId));
            if(result && !result.error) {
                this.isNew = false;
                this.title = `Edit service ${result.name}`;
                this.currentData = result;
                setTimeout(()=>{
                    this.batchServiceForm.setValue({
                        country: result.country,
                        name: result.name,
                        description: result.description,
                        successTransStockCode: result.successTransStockCode,
                        failedTransStockCode: result.failedTransStockCode,
                        admin: result.admin,
                        enabled: result.enabled,
                        serviceMapping: result.serviceMapping
                      });
                }, 100);
              
            } else {
                this.router.navigateByUrl('/');
            }
        }
    }

    async onSubmit() {
        if(this.batchServiceForm.valid) {
            const batchServiceForm: BatchSvcForm = this.batchServiceForm.value;
            this.isBatchCreateLoading = true;
            let res: any;
            let msg = '';
            if(this.isNew) {
                 res = await lastValueFrom(this.batchToolService.createBatchService(batchServiceForm));
                 msg = 'Batch service created succesfully.';
            } else {
                const newEditedForm: BatchServiceInfo = {
                    ...this.currentData,
                    ...batchServiceForm
                };
                res = await lastValueFrom(this.batchToolService.updateBatchService(newEditedForm));
                msg = 'Batch service updated succesfully.';
            }
            if(res && !('error' in res)) {
                this.messageService.add({
                    key: 'br',
                    severity: 'primary',
                    summary: 'Success',
                    detail: msg,
                    closable: false,
                });
                this.batchServiceStore.updateIsLoaded(false);
                this.router.navigateByUrl('/batch/services');
                this.batchServiceForm.reset();
            }
            else if(res && ('error' in res)) {
                this.messageService.add({
                    key: 'br',
                    severity: 'error',
                    summary: 'Error',
                    detail: res.error.message,
                    closable: false,
                });
            }
            this.isBatchCreateLoading = false;
        }
    }

    filteredByCountry(batchTypeData: BatchTypes[] | any[], country: any) {
        const filtered =  batchTypeData.filter(data=> 
            data.country == country
        );
        return filtered;
    }

    navigateOverview() {
        this.router.navigate([DEFAULT_BASE_URL]);
    }
}
