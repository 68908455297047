import { APP_INITIALIZER, NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import { environment } from 'src/environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthService } from './core/services/auth.service';
import { AuthModule } from './pages/auth/auth.module';
import { DashboardModule } from './pages/dashboard/dashboard.module';
import { SharedPrimeNgModule } from './shared/shared-prime-ng.module';
import { SharedModule } from './shared/shared.module';

import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { AuthInterceptorService } from './core/interceptors/auth.interceptor.service';
import { SessionStore, sessionPersistStorage } from './core/state/session/session.store';

import { AppInitializerService } from './core/services/app-initializer.service';



export function appInitializerFactory(appInitializer: AppInitializerService): () => Promise<unknown> {
  return () => appInitializer.initializeApp();
}
@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AngularFireModule.initializeApp(environment.firebase),
        AngularFireAuthModule,
        AngularFirestoreModule,
        AngularFireModule,
        AppRoutingModule,
        DashboardModule,
        SharedModule,
        SharedPrimeNgModule,
        LoadingBarModule,
        LoadingBarRouterModule,
        HttpClientModule,
        AuthModule,
        environment.production
            ? []
            : AkitaNgDevtools.forRoot({
                maxAge: 25,
            }),
    ],
    providers: [
        AuthService,
        AuthInterceptorService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptorService,
            multi: true,
        },
        SessionStore,
        { provide: 'persistStorage', useValue: sessionPersistStorage },
        AppInitializerService,
        {
            provide: APP_INITIALIZER,
            useFactory: appInitializerFactory,
            multi: true,
            deps: [AppInitializerService]
        }
    ],
    bootstrap: [AppComponent],
})
export class AppModule { }
