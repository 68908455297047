import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
    name: 'trimText',
})
export class TrimTextPipe implements PipeTransform {
    transform(value: any, noOfCharacter: number = 8): any {
        if (value) {
            return value.substring(0, noOfCharacter).toUpperCase();
        }
        return value;
    }
}
