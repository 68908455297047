<div class="title">{{ title }}</div>
<div class="add-batch">
    <button
        pButton
        class="mt-11 p-element p-ripple p-button p-component"
        (click)="goToAddBatch()"
    >
     <div class="icon-wrapper"> <span class="icon icon-plus icon-invert"></span> </div> Add a service
    </button>
</div>
<div class="table-wrapper">
    <app-dynamic-table
        [tableData]="{ data: (batchServiceQuery$ | async), cols: columnTable }"
        (actionEvent)="actionEvents($event)"
        [isLoading]="batchServiceIsLoading$ | async"
        [allowManualSearch]="true"
    ></app-dynamic-table>
</div>

<app-side-panel
    [isVisible]="isSidePanelVisible"
    (onHideEvent)="onHideEvent($event)"
>
    <div class="action-wrapper">
        <div class="title-header">
            <h1 ><span  class="icon icon-menu"></span> Bulk Data Services </h1>
        </div>
    </div>
    <ng-container *ngIf="currentSidePanel == 'cancel-panel'">
        <div class="action-wrapper">
            <div class="container">
                <div class="title">Are you sure you want to delete <b>{{ currentSelectedData.name }}</b> the service? </div>
                <p>This will remove access for all Batch Service users.</p>
                <div>
                    <button
                        (click)="removeService()"
                        [disabled]="batchServiceIsLoading$ | async"
                        class="mt-11 w-100 text-center p-element btn-style p-ripple p-button p-component"
                    >
                        <span class="icon icon-remove icon-invert"> </span>
                        Delete
                    </button>

                    <button
                        pButton
                        (click)="onHideEvent(false)"
                        [disabled]="batchServiceIsLoading$ | async"
                        class="mt-11 w-100 text-center p-element p-ripple p-button-outlined btn-style p-button p-component"
                    >
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="currentSidePanel == 'enable-panel'">
        <div class="action-wrapper">
            <div class="container">
                <div class="title">Are you sure you want to {{isAllUser ? 'enable' : 'disable' }}  this service for all users?</div>
                <div>
                    <button
                        (click)="patchService(false)"
                        [disabled]="batchServiceIsLoading$ | async"
                        class="mt-11 w-100 text-center p-element btn-style p-ripple p-button p-component"
                    >
                        <span class="icon icon-check icon-invert"> </span>
                        {{isAllUser ? 'Enable' : 'Disable' }} 
                    </button>

                    <button
                        pButton
                        (click)="onHideEvent(false)"
                        [disabled]="batchServiceIsLoading$ | async"
                        class="mt-11 w-100 text-center p-element p-ripple p-button-outlined btn-style p-button p-component"
                    >
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="currentSidePanel == 'admin-panel'">
        <div class="action-wrapper">
            <div class="container">
                <div class="title">
                    “Are you sure you want to make the service {{isAdmin ? 'available' : 'disable' }} to only Admin accounts?</div>
                <div>
                    <button
                        (click)="patchService(true)"
                        [disabled]="batchServiceIsLoading$ | async"
                        class="mt-11 w-100 text-center p-element btn-style p-ripple p-button p-component"
                    >
                        <span class="icon icon-check icon-invert"> </span>
                        Admin
                    </button>

                    <button
                        pButton
                        (click)="onHideEvent(false)"
                        [disabled]="batchServiceIsLoading$ | async"
                        class="mt-11 w-100 text-center p-element p-ripple p-button-outlined btn-style p-button p-component"
                    >
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    </ng-container>
</app-side-panel>
