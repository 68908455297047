<ng-container *ngIf="tableData">
    <div *ngIf="allowManualSearch" class="form-input-search" >
        <div class="row">
            <div class="col-md-6">
                <span class="p-input-icon-left wb-100">
                    <i class="pi pi-search"></i>
                    <input type="text" class="w-100" pInputText [(ngModel)]="searchStringQuery" (keyup)="searchFilter()"
                    placeholder="Search"/>
                </span>
            </div>
        </div>
    </div>
    <p-table [columns]="tableData.cols" [value]="allowManualSearch ? filteredData : tableData.data" [loading]="isLoading" [scrollable]="true"
        [loadingIcon]="'pi pi-spin pi-spinner'" scrollHeight="{{ scrollHeight }}" [virtualScroll]="true"
        [virtualScrollItemSize]="100">
        <ng-template pTemplate="header" let-columns>
            <tr>
                <ng-container *ngFor="let col of tableData.cols">
                    <th [style]="col.styleCss">{{ col.header }}</th>
                </ng-container>
            </tr>
        </ng-template>

        <ng-template pTemplate="loadingBody" let-rowData let-columns="columns">
            <tr style="height: 46px">
                <td *ngFor="let col of columns; let even = even">
                    <p-skeleton [ngStyle]="{
                            width: even
                                ? col.field === 'year'
                                    ? '30%'
                                    : '40%'
                                : '60%'
                        }"></p-skeleton>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr>
                <td [colSpan]="tableData.cols.length"><div class="text-center">No records found.</div></td>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr>
                <ng-container *ngFor="let col of columns">
                    <ng-container [ngSwitch]="col.type">
                        <td *ngSwitchCase="'progress-bar'">
                            <p>
                                {{ rowData[col.combineFields[0]] }} /
                                {{ rowData[col.combineFields[1]] }}
                            </p>
                            <div>
                                <p-progressBar [value]="
                                        (rowData[col.combineFields[0]] * 100) /
                                        rowData[col.combineFields[1]]
                                    " [showValue]="false" [style]="{ height: '3px' }">
                                </p-progressBar>
                            </div>
                        </td>
                        <td *ngSwitchCase="'date'">
                            {{
                            (rowData[col.field] | dateOrEmpty)
                            ? (rowData[col.field] | date : 'dd/MM/yyyy')
                            : 'Invalid Date'
                            }}
                        </td>
                        <td *ngSwitchCase="'id'">
                            {{ rowData[col.field] | trimText }}
                        </td>
                        <td *ngSwitchCase="'errors'">
                            <div class="error" (click)="onActionEvent(rowData, 'error')">
                                View Errors
                            </div>
                        </td>

                        <td *ngSwitchCase="'action-icon'">
                            <div class="text-center">
                                <div class="action-icon" [class.disabled]="
                                        col.disabledFromField &&
                                        rowData[col.disabledFromField.field] &&
                                        col.disabledFromField.values.includes(
                                            rowData[col.disabledFromField.field]
                                        )
                                    " (click)="
                                        col.disabledFromField &&
                                        rowData[col.disabledFromField.field] &&
                                        col.disabledFromField.values.includes(
                                            rowData[col.disabledFromField.field]
                                        )
                                            ? {}
                                            : onActionEvent(rowData, col.action)
                                    ">
                                    <span class="{{ 'icon-m-zero ' + col.actionIcon }}"> </span>
                                </div>
                            </div>
                        </td>

                        <td *ngSwitchCase="'status'">
                            <div class="status-wrapper text-center">
                                <span pTooltip="{{
                                        rowData[col.field]
                                            | statusIcon : 'tooltip'
                                    }}" tooltipPosition="top" class="{{
                                        rowData[col.field] | statusIcon
                                    }}"></span>
                                <p>
                                    {{
                                    rowData[col.field]
                                    | statusIcon : 'label'
                                    }}
                                </p>
                            </div>
                        </td>


                        <td *ngSwitchCase="'boolean-select'">
                            <div class="text-center">
                                <div class="action-icon text-center" (click)="col.disabledFromField &&
                                        rowData[col.disabledFromField.field] &&
                                        col.disabledFromField.values.includes(rowData[col.disabledFromField.field] )
                                            ? {}
                                            : onActionEvent(rowData, col.action)">
                                    <span class="{{
                                            rowData[col.field] ? 'icon icon-m-zero icon-radio-selected' : 'icon icon-m-zero icon-radio-unselected '
                                        }}"></span>

                                </div>
                            </div>
                        </td>
                        <td *ngSwitchDefault>{{ rowData[col.field] }}</td>
                    </ng-container>
                </ng-container>
            </tr>
        </ng-template>
    </p-table>
</ng-container>